<template>
  <div class="evaluationList">
    <ul class="flex_jcsb" v-if="countData">
        <li>
          <div class="flex_jcsb_ac haoping"><img src="../../assets/images/icon/haoping.png" /> 好评 </div>
          <span>{{countData.praise || 0}}</span>
        </li>
        <li>
          <div class="flex_jcsb_ac zhongping"><img src="../../assets/images/icon/zhongping.png" /> 中评 </div>
          <span>{{countData.middle_review || 0}}</span>
        </li>
        <li>
          <div class="flex_jcsb_ac chaping"><img src="../../assets/images/icon/chaping.png" /> 差评 </div>
          <span>{{countData.bad_review || 0}}</span>
        </li>
      </ul>
    <div v-for="(item,index) in list" :key="index" class="flex evaluationBox">
      <img :src="item.user.avatarUrl || '../../assets/images/touxiang2.png'" alt="">
      <div class="evaluation">
        <div class="flex_jcsb_ac">
          <div class="name">{{item.user.nickName}}</div>
          <div class="tiem">{{item.create_time}}</div>
        </div>
        <div class="conents">{{item.content}}</div>
        <div class="flex_jcsb_ac itemBox">
          <div class="item">{{item.project ? item.project.name : '暂无'}}</div>
          <div class="appraise">{{item.type === 3 ? '好评' : item.type === 2 ? '中评' : item.type === 1 ? '差评' :'未评价'}}</div>
        </div>
      </div>
    </div>
    <el-empty v-if="!list.length"></el-empty>
  </div>
</template>

<script>
export default {
  name: 'EvaluationList',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    countData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
  .evaluationList{
    background: #fff;
    .evaluationBox{
      border-bottom: 1px solid #E8E8E8;
      padding: 16px 0;
      .evaluation{
        margin-left: 16px;
        flex: 1;
        margin-top: 15px;
        .itemBox{
          flex: 1;
          margin-top: 16px;
          .item{
            color: #999;
            font-size: 16px;
          }
          .appraise{
            color: #FFBA00;
            font-size: 16px;
          }
        }
        .conents{
          margin-top: 18px;
        }
        .name{
          font-size: 18px;
          color: #666;
        }
        .tiem{
          color: #666;
          font-size: 14px;
        }
      }
      img{
        width: 48px;
        height: 48px;
        border-radius: 50%;
      }
    }
    ul{
      width: 400px;
      margin: auto;
      li {
        text-align: center;
        .haoping i{
          color: #FF8004;
        }
        .zhongping i{
          color: #FFC600;
        }
        .chaping i{
          color: #999;
        }
        div {
          width: 70px;
          font-size: 16px;
        }
        i {
          font-size: 30px;
        }
        span {
          font-size: 20px;
          color: #2883E9;
        }
      }
    }
  }
</style>
