<template>
  <div class="pagination flex_jc_ac">
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="changeCurrentPage"
      :page-size="pageSize"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 3
    }
  },
  methods: {
    changeCurrentPage(value) {
      this.$emit('changeCurrentPage', value)
    }
  }
}
</script>
<style lang="scss" scoped>
  .pagination{
    /deep/{
      .el-pagination{
        margin: 0 auto;
        .el-pager{
          li{
            width: 35px;
            height: 35px;
            line-height: 35px;
            border: 1px solid #DDDDDD;
            background: #fff;
            color: #999999;
            font-weight: normal;
            font-size: 16px;
            // border-radius: 4px;
            &:hover{
              color: #1166CF;
            }
          }
        }
        &.is-background{
          .btn-prev, .btn-next{
            width: 35px;
            height: 35px;
            line-height: 35px;
            border: 1px solid #DDDDDD;
            background: #fff;
            .el-icon{
              font-size: 16px;
            }
          }
          .el-pager .active{
            border: none;
            background: #1166CF;
            &:hover{
              color: #fff;
            }
          }
        }
      }
    }
  }
</style>
