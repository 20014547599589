<template>
  <div class="myComment">
     <EvaluationList :countData="countData" :list="evaluationList" />
     <div class="buttonNumber">
       <Pagination :total="totalCount" :pageSize="8" @changeCurrentPage="changeCurrentPage" v-if="evaluationList.length" />
     </div>
  </div>
</template>

<script>
import EvaluationList from '@/components/evaluation/evaluationList'
import Pagination from '@/components/common/pagination'
import {evaluationList} from '@/api/user/user'
export default {
  name: 'myComment',
  components: {
    EvaluationList,
    Pagination
  },
  data() {
    return {
      evaluationList: [],
      totalCount: 0,
      page: 1,
      countData: {}
    }
  },
  mounted() {
    this.evaluation()
  },
  methods: {
    changeCurrentPage(value) {
      this.page = value
      this.evaluation()
    },
    evaluation() {
      const data = {
        psize: 8,
        page: this.page
      }
      evaluationList(data).then(res => {
        const data = res.data
        this.countData = data.count_data
        this.totalCount = data.count
        this.evaluationList = data.list
        // console.log('打印', res.data.list)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .myComment{
    background: #fff;
    padding: 40px 32px 40px 32px;
    min-height: 566px;
    margin-top: 12px;
    .buttonNumber{
      margin: 40px auto 0;
    }
  }
</style>
